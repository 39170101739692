import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/content/contentContainer"

export default ({ data, location }) => {
  const {
    contentfulBrand: { twitter },
    contentfulLayoutTemplate: {
      title,
      type,
      seoTitle,
      shortDescription,
      slug,
      canonical,
      published,
      image,
      author,
      blocks,
      categories,
      tags,
    },
  } = data

  return (
    <Layout location={location} pageTitle={title}>
      <SEO
        title={seoTitle}
        description={shortDescription}
        pathname={slug}
        canonical={canonical}
      />
      <ContentContainer
        content={{
          title,
          type,
          image,
          author,
          blocks,
          categories,
          tags,
          twitter,
          location,
          published,
        }}
      />
    </Layout>
  )
}

export const postPageQuery = graphql`
  query($postId: String) {
    contentfulBrand {
      twitter
    }
    contentfulLayoutTemplate(type: { eq: "Post" }, id: { eq: $postId }) {
      id
      type
      title
      slug
      canonical
      seoTitle
      shortDescription
      published(formatString: "MMM D, YYYY")
      categories {
        id
        title
        slug
      }
      tags {
        id
        title
        slug
      }
      image {
        id
        file {
          url
        }
        fluid(maxWidth: 1200) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
        description
      }
      author {
        name
        slug
        canonical
        shortDescription
        image {
          id
          fluid(maxWidth: 580) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          description
        }
      }
      blocks {
        __typename
        id
        title
        components {
          ... on Node {
            __typename
            id
            ... on ContentfulContentComponent {
              id
              title
              text {
                childMarkdownRemark {
                  htmlAst
                  fields {
                    readingTime {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
